<template>
  <div class="analyze-hada">
    <div class="container">

      <div class="hada">
        <label>皮膚色調</label>
        <div class="row mt-2">
          <div class="col-6">
            <button class="btn btn-hada text-main-text" :class="{'active': hada === 1}" @click="saveHadairoClicked(1)">
              <img :src="require(`@/assets/images/hada_cold.png`)" />
              <p>冷色調</p>
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-hada text-main-text" :class="{'active': hada === 2}" @click="saveHadairoClicked(2)">
              <img :src="require(`@/assets/images/hada_warm.png`)" />
              <p>暖色調</p>
            </button>
          </div>
        </div>

        <div v-if="hada === 1" class="hadaSnippist mt-2">
          <b-button block v-b-toggle.accordion-1 size="sm" class="mb-2 cold-btn">
            關於冷色調肌膚
          </b-button>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card no-body>
              <b-card-body>
                <b-card-text>以素色上衣為例，你適合穿藍色基調的衣服；不建議穿著暖黃、橘色基調的上衣，大面積的色塊，容易使你看起來膚色暗沈、蠟黃。<br/><br/>
                在挑選粉底時，選擇藍色調為基底的粉底能和自身肌膚達到和諧；最適合你的眼影、口紅、腮紅的顏色是玫瑰紅、紫粉色等色系。</b-card-text>
              </b-card-body>
            </b-card>
          </b-collapse>
        </div>

        <div v-if="hada === 2" class="hadaSnippist mt-2">
          <b-button block v-b-toggle.accordion-2 size="sm" class="mb-2 warm-btn">
            關於暖色調肌膚
          </b-button>
          <b-collapse id="accordion-2" accordion="my-accordion2" role="tabpanel">
            <b-card no-body>
              <b-card-body>
                <b-card-text>以素色上衣為例，你適合穿黃、橘色基調的衣服，不建議穿著藍色基調的上衣，大面積的色塊，容易使你看起來沒精神、凸顯黑眼圈。<br/><br/>
                在挑選粉底時，選擇黃色調為基底的粉底能和自身肌膚達到和諧；最適合你的眼影、口紅、腮紅的顏色是橘色調、鮭魚粉等色系。</b-card-text>
              </b-card-body>
            </b-card>
          </b-collapse>
        </div>

        <p class="mt-3">想更了解自己是什麼皮膚色調嗎？試試看由莫菲形象管理設計的 5 tips 皮膚冷暖色判別法吧！</p>

        <button class="btn btn-hada" @click="openTest">
          <h6 class="mb-0 text-main-text">5 Tips 皮膚冷暖色判別法</h6>
          <hr/>
          <h6 class="mb-0 text-orange font-weight-bold">立刻測試</h6>
        </button>

        <p class="text-center mt-4 sponsor">本測驗由 <span class="font-weight-bold">莫菲形象管理</span> 提供</p>

      </div>
    </div>

    <transition name="fade">
      <AnalyzeHadaModal v-if="isTestShow" @close="closeTest" @update="updateHadairo"></AnalyzeHadaModal>
    </transition>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import AnalyzeHadaModal from './AnalyzeHadaModal.vue';

export default {
  name: 'AnalyzeBody',
  data() {
    return {
      readingBusyName: 'READINGHADADATA',
      saveBusyName: 'SAVEHADACOLOR',

      hada: 0,
      isTestShow: false,
    };
  },
  components: {
    AnalyzeHadaModal,
  },
  props: {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
    this.clearComponentBusy(this.saveBusyName);
  },
  async mounted() {
    await this.__refresh();
  },
  computed: {
    ...mapGetters(['isBodyConfigShow']),
    hadaStr() {
      switch(this.hada) {
        case 0:
          return '未知';
        case 1:
          return '冷色調';
        case 2:
          return '暖色調';
      }
      return '';
    },
    hadaClass() {
      switch(this.hada) {
        case 0:
          return 'hada-unknown';
        case 1:
          return 'hada-cold';
        case 2:
          return 'hada-warm';
      }
      return '';
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const userBody = await this.$store.dispatch('api/getUserBodyDataPromise');
        this.hada = userBody.hada;
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    async saveHadairoClicked(hada) {
      this.appendComponentBusy(this.saveBusyName);
      try {
        await this.$store.dispatch('api/postUpdateUserHadaPromise', hada);
        this.hada = hada;
      } catch (error) {
        console.error(error);
      } finally {
        this.clearComponentBusy(this.saveBusyName);
      }
    },
    openTest() {
      this.isTestShow = true;
    },
    closeTest() {
      this.isTestShow = false;
    },
    updateHadairo(hada) {
      this.hada = hada;
    }
  } 
}
</script>

<style scoped>
  .analyze-hada {
    padding: .5rem 0;
  }

  .hada {
    padding: 15px 20px;
  }

  .hada>label {
    margin: 0;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    font-weight: 1000;
  }

  .hada .btn-hada {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: block;
    width: 100%;
    padding: 15px 10px;
    border: solid 2px #d3d2d2;
  }

  .hada .btn-hada:focus, .profile .hada .btn-hada:hover {
    outline: none;
    box-shadow: none;
    border-color: #e6b479;
  }

  .hada .btn-hada.active {
    box-shadow: 0 0 0 2px #e6b479;
    /* border-width: 3px; */
    border-color: #fff;
  }

  .hada .btn-hada>img {
    width: 60px;
  }

  .profile .hada .btn-hada>p {
    margin: 0;
    margin-top: 1rem;
  }

  .cold-btn {
    color: #5755eb;
    border-color: #5755eb;
    background-color: transparent;
  }

  .cold-btn:focus, .cold-btn:active, .cold-btn:hover {
    background-color: #5755eb !important;
    color: #fff !important;
    box-shadow: none !important;
  }

  .warm-btn {
    color: #f59d0f;
    border-color: #f59d0f;
    background-color: transparent;
  }

  .warm-btn:focus, .warm-btn:active, .warm-btn:hover {
    background-color: #f59d0f !important;
    color: #fff !important;
    box-shadow: none !important;
  }
</style>
