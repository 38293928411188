<template>
  <FullScreenModal title="5 Tips 皮膚冷暖色判別法" @back="backClicked">
    <div class="test-outer">
      <transition name="card-switch" v-for="(item, index) in qs" :key="index">
        <div class="step-card" v-if="index === testStep">
          <p class="step-title" v-text="item.title"></p>
          <button class="btn btn-selection" :class="{'active': nowSelection === 'cold'}" @click="selectBtnClicked('cold')">
            <span v-text="item.cold"></span>
            <span class="not-selected"><span class="icon-circle"></span></span>
            <span class="selected"><span class="icon-circle filled"></span></span>
          </button>
          <button class="btn btn-selection" :class="{'active': nowSelection === 'warm'}" @click="selectBtnClicked('warm')">
            <span v-text="item.warm"></span>
            <span class="not-selected"><span class="icon-circle"></span></span>
            <span class="selected"><span class="icon-circle filled"></span></span>
          </button>
          <p class="text-center mt-4 sponsor">本測驗由 <span class="font-weight-bold">莫菲形象管理</span> 提供</p>
          

        </div>
        
      </transition>

      <transition name="card-switch">
        <div class="step-card" v-if="testStep === 5">
          <div class="col-6 mx-auto mb-2">
            <img :src="require(`@/assets/images/hada_cold.png`)" v-if="isCold" class="img-fluid" />
            <img :src="require(`@/assets/images/hada_warm.png`)" v-if="!isCold" class="img-fluid" />
          </div>
          <p class="text-center font-weight-bold">你的肌膚是 <span v-text="isCold?'冷色調':'暖色調'"></span></p>
          <p v-if="isCold">
            以素色上衣為例，你適合穿藍色基調的衣服；不建議穿著暖黃、橘色基調的上衣，大面積的色塊，容易使你看起來膚色暗沈、蠟黃。<br/><br/>
            在挑選粉底時，選擇藍色調為基底的粉底能和自身肌膚達到和諧；最適合你的眼影、口紅、腮紅的顏色是玫瑰紅、紫粉色等色系。
          </p>
          <p v-if="!isCold">
            以素色上衣為例，你適合穿黃、橘色基調的衣服，不建議穿著藍色基調的上衣，大面積的色塊，容易使你看起來沒精神、凸顯黑眼圈。<br/><br/>
            在挑選粉底時，選擇黃色調為基底的粉底能和自身肌膚達到和諧；最適合你的眼影、口紅、腮紅的顏色是橘色調、鮭魚粉等色系。
          </p>

          <div class="col-8 mx-auto mt-4 mb-2">
            <div class="row justify-content-center">
              <a class="link-item" href="#" @click="reTest">
                <img :src="require(`@/assets/icons/ic_rotate_left_24px.png`)" />
                <p>再測一次</p>
              </a>

              <!-- <a class="link-item" href="#" data-clipboard-text="https://lin.ee/mrz2lA8">
                <img :src="require(`@/assets/icons/ic_share_24px.png`)" />
                <p>分享給朋友</p>
              </a> -->
            </div>
          </div>

          <hr/>

          <p class="text-center font-weight-bold">本測驗由 莫菲形象管理 提供</p>

          <p>
            莫菲穿搭提供各式穿搭課程（包含表達&amp;儀態訓練）、衣櫥診斷、陪同購衣等服務，讓更多人透過系統化的科學方法，真正的了解自己，並感受皮膚色彩屬性與服裝風格穿搭之間的關係，即能以少件衣服滿足生活、職場、聚會等各需求面向，從此買對穿對更達到內外在理想形象的平衡。
          </p>

          <div class="col-8 mx-auto m4-4">
            <div class="row justify-content-between">
              <a class="link-item" target="_blank" href="https://portaly.cc/fayemoment">
                <img :src="require(`@/assets/icons/ic_public_24px.png`)" />
                <font-awesome-icon :icon="['fas', 'earth-americas']" />
                <p>莫菲形象管理</p>
              </a>

              <a class="link-item" target="_blank" href="https://www.youtube.com/@fayemoment">
                <img :src="require(`@/assets/icons/ic_fb.png`)" />
                <font-awesome-icon :icon="['fab', 'youtube']" />
                <p>莫菲穿搭</p>
              </a>
            </div>
          </div>

        </div>
      </transition>

    </div>
  </FullScreenModal>
</template>

<script>
import { mapActions } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';
import '@/assets/css/buttons.css';

export default {
  name: 'CreateCloth',
  data() {
    return {
      saveBusyName: 'HADARESULTSAVE',
      testStep: 0,
      nowSelection: '',
      nowSum: 0,
      qs: [
        {
          title: '衣櫥裡，哪種顏色基調的衣服最多？',
          cold: '藍色或紫色',
          warm: '黃色或橘色'
        },
        {
          title: '過往的經驗中，自己最適合配戴什麼顏色的飾品/手錶/眼鏡框等配件呢？',
          cold: '銀色',
          warm: '金色'
        },
        {
          title: '曬太陽時，若不擦防曬產品，皮膚最常發生以下哪種情況呢？',
          cold: '曬後皮膚容易發紅',
          warm: '曬後皮膚變黃褐色'
        },
        {
          title: '仔細看自己瞳孔中的虹膜，會偏向以下哪種顏色呢？',
          cold: '黑色或深棕色',
          warm: '棕色或琥珀色'
        },
        {
          title: '你覺得自己適合以下哪種髮色呢？',
          cold: '黑色',
          warm: '棕/咖啡色'
        },
      ]
    };
  },
  components: {
    FullScreenModal,
  },
  props: {
  },
  created() {
  },
  beforeDestroy() {
  },
  async mounted() {
    
  },
  computed: {
    isCold: function() {
      return this.nowSum < 0;
    }
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    backClicked() {
      this.$emit('close');
    },
    selectBtnClicked(sel) {
      if (this.nowSelection === '') {
        this.nowSelection = sel;
        setTimeout(() => {
          if (this.nowSelection === 'cold') {
            this.nowSum -= 1;
          } else {
            this.nowSum += 1;
          }
          this.nowSelection = '';
          this.testStep += 1;

          if (this.testStep === 5) {
            if (this.isCold) {
              this.saveHadairoClicked(1);
            } else {
              this.saveHadairoClicked(2);
            }
          }
        }, 1000);
      }
    },
    async saveHadairoClicked(hadairo) {
      this.appendComponentBusy(this.saveBusyName);
      try {
        await this.$store.dispatch('api/postUpdateUserHadaPromise', hadairo);
        this.$emit('update', hadairo);
      } catch (error) {
        console.error(error);
      } finally {
        this.clearComponentBusy(this.saveBusyName);
      }
    },
    reTest() {
      this.testStep = 0;
      this.nowSum = 0;
      this.nowSelection = '';
    },
  }
}
</script>

<style scoped>
  .test-outer {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .test-outer .div-header {
    flex: 0 0 auto;
    border-bottom: solid 1px #d3d3d3;
    text-align: center;
    position: relative;
    padding: 10px;
  }

  .test-outer .div-header .close-btn {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  .test-outer .div-body {
    flex: 1 0 auto;
    /* background-color: #f0f; */
    position: relative;
  }

  .outer-enter-active, .outer-leave-active {
    transition: opacity .4s ease, transform .4s ease;
    transform-origin: center center;
  }

  .outer-enter, .outer-leave-to {
    opacity: 0;
    transform: scale(.95);
  }

  .outer-enter-to, .outer-leave {
    opacity: 1;
    transform: scale(1);
  }

  .step-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px 30px 80px 30px;
    overflow-y: auto;
  }

  .step-card .step-title {

  }

  .step-card .btn.btn-selection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px 10px;
    border: solid 2px transparent;
  }

  @keyframes shining-border {
    0% {
      border-color: transparent;
    }

    100% {
      border-color: var(--main-orange);
    }
  }

  .step-card .btn.btn-selection.active {
    animation-name: shining-border;
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .step-card .btn.btn-selection .not-selected {
    display: block;
  }

  .step-card .btn.btn-selection .selected {
    display: none;
  }

  .step-card .btn.btn-selection.active .not-selected {
    display: none;
  }

  .step-card .btn.btn-selection.active .selected {
    display: block;
  }

  .step-card .btn.btn-selection:hover, .step-card .btn.btn-selection:focus {
    outline: none;
    box-shadow: none;
  }

  .card-switch-enter-active, .card-switch-leave-active {
    transition: opacity .4s ease, transform .4s ease;
  }

  .card-switch-enter {
    opacity: 0;
    transform: translate(100%, 0);
  }

  .card-switch-enter-to, .card-switch-leave {
    opacity: 1;
    transform: translate(0, 0);
  }

  .card-switch-leave-to {
    opacity: 0;
    transform: translate(-100%, 0);
  }

  .link-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--main-orange);
  }

  .link-item>img {
    display: block;
    margin-bottom: 5px;
    height: 24px;
  }

  .link-item>p {
    color: var(--main-text);
    font-size: .8rem;
  }

  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .sponsor {
    font-size: 10px;
  }

  .icon-circle {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: solid 1px #000;
  }

  .icon-circle.filled {
    background-color: #000;
  }
</style>
